import { render, staticRenderFns } from "./CommentMain.vue?vue&type=template&id=ea95df9c&scoped=true&"
import script from "./CommentMain.vue?vue&type=script&lang=js&"
export * from "./CommentMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea95df9c",
  null
  
)

export default component.exports